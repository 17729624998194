import {mapActions, mapGetters} from "vuex";

export default {
  name: 'feedback-edit',
  data() {
    return {
      editItem: {}
    }
  },
  computed: {
    ...mapGetters({
      item: 'feedbacksEdit/item',
      loading: 'feedbacksEdit/itemLoading',
    }),
  },
  methods: {
    ...mapActions({
      fetchItem: 'feedbacksEdit/GET_FEEDBACK',
      remove: 'feedbacks/DELETE_FEEDBACK',
      save: 'feedbacksEdit/SAVE_FEEDBACK',
    }),
    deleteFeedback() {
      this.remove({id: this.$route.params.feedback_id}).then(() => {
        this.$toasted.success(this.$t('success'));
        this.$router.push({ name: 'feedbacks' }).catch(() => console.log())
      })
    },
    saveFeedback() {
      this.save(this.editItem).then(() => {
        this.$toasted.success(this.$t('success'));
        this.fetchItem({id: this.$route.params.feedback_id})
      })
    }
  },
  created() {
    window.editFeed = this
    this.fetchItem({id: this.$route.params.feedback_id}).then(() => {
      this.editItem = this.item;
    })
  }
}
